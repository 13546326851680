/** @jsxImportSource @emotion/react */

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EnvelopeIcon,
  HomeIcon,
  KeyIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Button, Input } from '@sal-solution/ui-web';
import { FC } from 'react';
import 'twin.macro';
import { useLocale } from '../../../hooks/useLocal';
import { steps } from '../hooks/useSignup';
import { locales } from '../locale/signUpLocale';
import { useSignUp } from '@/contexts/SignUpContext';
import { toast } from 'react-toastify';

type UserInformationProps = {
  onNextStep: (e: steps) => void;
};

export const UserInformation: FC<UserInformationProps> = ({ onNextStep }) => {
  const locale = useLocale(locales);
  const { data, onChangeData, setStep } = useSignUp();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const firstName = formData.get('firstName') as string;
    const lastName = formData.get('lastName') as string;
    const email = formData.get('email') as string;
    const phone = formData.get('phone') as string;
    const password = formData.get('password') as string;
    const confirmPassword = formData.get('confirmPassword') as string;

    if (password !== confirmPassword) {
      toast.error(locale['Passwords do not match']);
      return;
    }

    onChangeData('firstName', firstName);
    onChangeData('lastName', lastName);
    onChangeData('email', email);
    onChangeData('phone', phone);
    onChangeData('password', password);
    onChangeData('confirmPassword', confirmPassword);

    setStep(3);
  };

  return (
    <form tw="space-y-4" onSubmit={onSubmit}>
      <Input
        iconLeft={<UserIcon />}
        placeholder={locale.FirstName}
        name="firstName"
        required
      />
      <Input
        iconLeft={<LockClosedIcon />}
        placeholder={locale.LastName}
        name="lastName"
        required
      />
      <Input
        iconLeft={<EnvelopeIcon />}
        placeholder={locale.Email}
        name="email"
        required
      />
      <Input
        iconLeft={<PhoneIcon />}
        placeholder={locale.Phone}
        name="phone"
        required
      />
      {/* <Input iconLeft={<HomeIcon />} placeholder={locale.City} name="city" /> */}
      <Input
        iconLeft={<KeyIcon />}
        type="password"
        placeholder={locale.Password}
        name="password"
        required
      />
      <Input
        iconLeft={<KeyIcon />}
        type="password"
        placeholder={locale['Confirm Password']}
        name="confirmPassword"
        required
      />
      <div tw="flex gap-4">
        <Button
          tw="flex items-center justify-center gap-2"
          type="button"
          onClick={() => setStep(1)}
        >
          <ArrowLeftIcon tw="w-4" />
          {locale.Back}
        </Button>
        <Button tw="flex items-center justify-center gap-2" variant="success">
          {locale.Next}
          <ArrowRightIcon tw="w-4" />
        </Button>
      </div>
    </form>
  );
};

/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { FC } from 'react';
import {
  Section,
  Text,
  Countdown,
  Button,
  HeroSection,
} from '@sal-solution/ui-web';
import tw from 'twin.macro';
import Link from 'next/link';
import { localeEN, localeNL } from '@/utils/locale';
import { useLocale } from '@/hooks/useLocal';
const highLightGreenBefore = tw`before:([content: " "] bg-green-200 rotate-3 w-full h-full absolute top-0 left-0 z-0)`;

export const Deal: FC<{ id?: string }> = ({ ...rest }) => {
  const locale = useLocale(locales);
  const from = process.env.DEAL_EXPIRES_AT || 'Sept 30, 2024 00:00:00';
  return (
    <HeroSection
      bottomVariant="Wave"
      tw="bg-amber-50 items-center"
      topFill="#fff"
      bottomFill="#fff"
      {...rest}
    >
      <Section tw="flex-col space-y-8 py-12 lg:(py-16) 2xl:(py-24)">
        <div>
          <Text tw="text-5xl w-full text-center" variant="title">
            {locale.tryIt}{' '}
            <span tw="relative" css={[highLightGreenBefore]}>
              <span tw="relative z-10">{locale.free}</span>
            </span>{' '}
            {locale.out}
          </Text>
          <Text tw="w-full mt-4 text-center text-xl">{locale.nextDays}</Text>
        </div>

        <Countdown from={from} modifier="home-page" />
        <Button as={Link} href="#packages">
          {locale.CTA}
        </Button>
      </Section>
    </HeroSection>
  );
};

const locales = {
  [localeNL]: {
    tryIt: 'Probeer het',
    free: 'gratis',
    out: 'uit!',
    nextDays:
      'Rentwatcher is tijdelijk de eerste 2 weken gratis Probeer het uit.',
    CTA: 'Start nu',
  },
  [localeEN]: {
    tryIt: 'Try it',
    free: 'for free!',
    out: '',
    nextDays:
      'Rentwatcher is temporarily free for the first 2 weeks. Try it out.',
    CTA: 'Start now',
  },
};

/** @jsxImportSource @emotion/react */

import { SelectPackage } from '@/components/SelectPackage/SelectPackage';
import { useSignUp } from '@/contexts/SignUpContext';
import { useLocale } from '@/hooks/useLocal';
import { ArrowLeftIcon, CreditCardIcon } from '@heroicons/react/20/solid';
import {
  Button,
  Text,
  highLightColors,
  hightLightText,
} from '@sal-solution/ui-web';
import { FC } from 'react';
import 'twin.macro';
import { steps } from '../hooks/useSignup';
import { locales } from '../locale/signUpLocale';
import { useSubscribe } from '@/contexts/Subscribe.context';

type UserPackageProps = {
  onNextStep: (e: steps) => void;
};

export const UserPackage: FC<UserPackageProps> = ({ onNextStep }) => {
  const locale = useLocale(locales);
  const { onSubmit } = useSignUp();
  const { userPackage } = useSubscribe();

  const highLightedText = hightLightText(
    locale.tryItForFree,
    locale.highLight,
    highLightColors[0]
  );
  return (
    <form tw="space-y-4" onSubmit={onSubmit}>
      <Text tw="text-base 2xl:text-xl">{highLightedText}</Text>
      <SelectPackage />
      <div tw="flex gap-4">
        <Button
          tw="flex items-center justify-center gap-2"
          type="button"
          onClick={() => onNextStep(2)}
        >
          <ArrowLeftIcon tw="w-4" />
          {locale.Back}
        </Button>
        <Button
          variant="success"
          disabled={!userPackage}
          tw="flex items-center justify-center gap-2"
          type="submit"
        >
          {locale.Payment}
          <CreditCardIcon tw="w-4" />
        </Button>
      </div>
    </form>
  );
};

/** @jsxImportSource @emotion/react */

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Button, Text } from '@sal-solution/ui-web';
import { cities } from '@sal-solution/utils';
import { FC } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'twin.macro';
import { useLocale } from '../../../hooks/useLocal';
import { SettingsLocale } from '../../ProfilePage/tabs/Settings/Settings';
import { useSignUp } from '@/contexts/SignUpContext';
import { locales } from '../locale/signUpLocale';

export const UserPreference: FC = () => {
  const locale = useLocale(SettingsLocale);
  const userLocale = useLocale(locales);
  const citiesForSelect = cities.map((city) => ({ value: city, label: city }));
  const { onChangeData, setStep } = useSignUp();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const cities = formData.getAll('cities').map((city) => city as string);
    if (cities.length === 0) {
      toast.error('Please select at least one city');
      return;
    }

    const minRent = (formData.get('min-rent') as string) || '0';
    const maxRent = formData.get('max-rent') as string;

    onChangeData('cities', cities);
    onChangeData('minRent', minRent);
    onChangeData('maxRent', maxRent);

    setStep(2);
  };
  return (
    <form tw="space-y-4" onSubmit={onSubmit}>
      <Text variant="subTitle" tw="w-full text-left text-base">
        {locale.regions} <span tw="text-red-500">*</span>
      </Text>
      <Select
        required
        isMulti
        name="cities"
        options={citiesForSelect}
        className="cities-multi-select"
        classNamePrefix="select"
      />
      <Text variant="subTitle" tw="w-full text-left text-base">
        {locale.maxRent}
      </Text>
      <Select
        name="max-rent"
        options={rentOptions}
        className="max-rent-multi-select"
        classNamePrefix="select-max-rent"
        defaultValue={{ value: '2000', label: '€2000,-' }}
      />

      <Button
        variant="success"
        tw="flex items-center justify-center gap-2"
        type="submit"
      >
        {userLocale.Next}
        <ArrowRightIcon tw="w-4" />
      </Button>
    </form>
  );
};

const rentOptions = [
  {
    value: '500',
    label: '€500,-',
  },
  {
    value: '750',
    label: '€750,-',
  },
  {
    value: '1000',
    label: '€1000,-',
  },
  {
    value: '1250',
    label: '€1250,-',
  },
  {
    value: '1500',
    label: '€1500,-',
  },
  {
    value: '1750',
    label: '€1750,-',
  },
  {
    value: '2000',
    label: '€2000,-',
  },
  {
    value: '2250',
    label: '€2250,-',
  },
  {
    value: '2500',
    label: '€2500,-',
  },
  {
    value: '2750',
    label: '€2750,-',
  },
  {
    value: '3000',
    label: '€3000,-',
  },
  {
    value: '3250',
    label: '€3250,-',
  },
  {
    value: '3500',
    label: '€3500,-',
  },
  {
    value: '3750',
    label: '€3750,-',
  },
  {
    value: '4000',
    label: '€4000,-',
  },
  {
    value: '4250',
    label: '€4250,-',
  },
  {
    value: '4500',
    label: '€4500,-',
  },
  {
    value: '4750',
    label: '€4750,-',
  },
  {
    value: '5000',
    label: '€5000,-',
  },
];

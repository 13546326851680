'use client';
import {
  SubscribeContextType,
  useSubscribe,
} from '@/contexts/Subscribe.context';
import { useLocale } from '@/hooks/useLocal';
import { ApolloError } from '@apollo/client';
import { sendGTMEvent } from '@next/third-parties/google';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useCreateCheckoutUrlMutation } from '../modules/SignUpPage/graphql/createCheckoutUrl.generated';
import { useCreateUserMutation } from '../modules/SignUpPage/graphql/createUser.generated';
import { locales } from '../modules/SignUpPage/locale/signUpLocale';

type onChangeValue = string | string[] | boolean;

export type SignUpContextType = {
  data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
    city: string;
    minRent: string;
    maxRent: string;
    cities: string[];
    sendEmail: string;
    whatsapp: string;
  };
  step: number;
  loading: boolean;
  loadingCheckout: boolean;
  loadingUser: boolean;
  error?: ApolloError | undefined;
  setStep: (step: steps) => void;
  onNextStep: () => void;
  onChangeData: (key: string, value: onChangeValue) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
};

const defaultData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  city: '',
  minRent: '',
  maxRent: '',
  cities: [],
  sendEmail: '',
  whatsapp: '',
};

const SignUpContext = createContext<SignUpContextType>({
  data: defaultData,
  step: 1,
  loading: false,
  loadingCheckout: false,
  loadingUser: false,
  error: undefined,
  setStep: () => undefined,
  onNextStep: () => undefined,
  onChangeData: () => undefined,
  onSubmit: async () => undefined,
});

export type steps = 1 | 2 | 3 | 4;

export const SignUpProvider: FC<PropsWithChildren> = ({ children }) => {
  const [createCheckoutUrl, createCheckoutUrlResponse] =
    useCreateCheckoutUrlMutation();
  const [createUser, createUserResponse] = useCreateUserMutation();

  const [data, setData] = useState<SignUpContextType['data']>(defaultData);

  const [step, setStep] = useState<steps>(1);
  const { userPackage } = useSubscribe();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const {
        cities,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        phone,

        maxRent,
      } = data;
      const city = data.city || 'Unknown';
      const minRent = data.minRent || '0';
      const sendEmail = data.sendEmail || 'on';
      const whatsapp = data.whatsapp || 'on';

      if (cities.length === 0) {
        toast.error('Please select at least one city');
        return;
      }

      validateFields(
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        phone,
        city,
        userPackage ? userPackage : undefined
      );

      const name = `${firstName} ${lastName}`;
      const newSubscriber = {
        name,
        email,
        password,
        confirmPassword,
        phone,
        city,
      };

      validateSubscriber(newSubscriber);

      const createUserResponse = await createUser({
        variables: {
          input: {
            email: newSubscriber.email,
            name: newSubscriber.name,
            password: newSubscriber.password,
            tel: newSubscriber.phone,
            city: newSubscriber.city,
            lookingFor: {
              minRent: Number(minRent),
              maxRent: Number(maxRent),
              city: cities,
            },
            settings: {
              receiveUpdatesOnEmail: sendEmail === 'on',
              receiveUpdatesOnWhatsApp: whatsapp === 'on',
            },
          },
        },
      });

      if (createUserResponse.errors) {
        toast.error(createUserResponse.errors[0].message);
        return;
      } else if (createUserResponse.data?.createUser?._id)
        toast.success('User created successfully');

      const checkoutUrl = await createCheckoutUrl({
        variables: {
          input: {
            userId: createUserResponse.data?.createUser?._id,
            userPackage: userPackage,
          },
        },
      });
      if (checkoutUrl.errors) {
        toast.error(checkoutUrl.errors[0].message);
        return;
      }

      //window.open(checkoutUrl.data?.createCheckoutUrl, '_blank');
      // go to url
      if (checkoutUrl.data?.createCheckoutUrl)
        window.location.href = checkoutUrl.data?.createCheckoutUrl;
    } catch (err) {
      toast.error(String(err));
      console.log('err');
    }
  };

  const onNextStep = () => {
    setStep((prev) => (prev === 1 ? 2 : prev === 2 ? 3 : 1));
  };

  const onStep = (step: steps) => {
    sendGTMEvent({ event: 'step', value: `go-to-step-${step}` });
    console.log('step', step);
    setStep(step);
  };

  const onChangeData = (key: string, value: onChangeValue) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const loading =
    createCheckoutUrlResponse.loading || createUserResponse.loading;
  const error = createCheckoutUrlResponse.error || createUserResponse.error;

  return (
    <SignUpContext.Provider
      value={{
        data,
        step,
        error,
        loading,
        loadingCheckout: createCheckoutUrlResponse.loading,
        loadingUser: createUserResponse.loading,
        setStep: onStep,
        onChangeData,
        onNextStep,
        onSubmit,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = () => {
  const context = useContext(SignUpContext);
  const locale = useLocale(locales);

  const getStepTitle = () => {
    if (context.step === 1) {
      return locale['Sign Up (Step 1 of 3)'];
    }
    if (context.step === 2) {
      return locale['Sign Up (Step 2 of 3)'];
    }
    if (context.step === 3) {
      return locale['Sign Up (Step 3 of 3)'];
    }
    return locale['Sign Up (Step 3 of 3)'];
  };

  if (!context) {
    throw new Error('useSignUp must be used within a SignUpProvider');
  }

  const stepTitle = getStepTitle();

  return { ...context, stepTitle };
};

const validateSubscriber = (
  subscriber: SubscribeContextType['newSubscriber']
) => {
  if (!subscriber?.name) {
    throw new Error('Name is required');
  }
  if (!subscriber?.email) {
    throw new Error('Email is required');
  }

  if (!subscriber?.phone) {
    throw new Error('Phone is required');
  }
  if (!subscriber?.city) {
    throw new Error('City is required');
  }
  if (!subscriber?.password) {
    throw new Error('Password is required');
  }
  if (!subscriber?.confirmPassword) {
    throw new Error('Confirm Password is required');
  }
  if (subscriber?.password !== subscriber?.confirmPassword) {
    throw new Error('Passwords do not match');
  }
};

const validateFields = (...fields: (string | undefined)[]) => {
  fields.forEach((field, i) => {
    if (!field) {
      console.log('field', field, i);
      throw new Error(`Missing field`);
    }
  });
};
